<ng-template #header>
  <h3>"{{patient?.name || user?.name}} - {{followup.date | dateFormat}}"</h3>
</ng-template>

<div class="home-meeting-wrapper">
  <div class="home-meeting-upper-details">
  <div class="row">
    <div class="colstat colstat-small">
      <label>{{"meetingtype" | langVal}}</label>
      <app-gen-input disabled [actualObj]="followup" [errors]="errors" className="border-0"
                     fieldName="meetingtype_name" title_ph_lang="meetingtype" (keyupEvent)="validateField()"/>
    </div>
    <div class="colstat colstat-price">
      <label>{{"price" | langVal}}</label>
      <app-gen-input [tabindex]="-1" className="input-number border-0" [actualObj]="followup" [errors]="errors" numberKeyboard
                     fieldName="price"
                     (keyupEvent)="validateField()" (changeEvent)="save()" [disabled]="followup?.invoice_id_patient>0"/>
      <!-- [addonObject]="{after:{text:('price' | langVal)}}" -->
    </div>

    <div class="colstat colstat-extra-small">
      <label>{{"time" | langVal}}</label>
      <app-gen-input disabled [actualObj]="followup" [errors]="errors"  className="border-0"
                     fieldName="time" (keyupEvent)="validateField()"/>
      <!-- [addonObject]="{after:{icon:'time'}}" -->
    </div>
    <div class="colstat colstat-extra-small" style="margin-inline-end: 0">
      <label>{{"length" | langVal}}</label>
      <app-gen-input disabled [actualObj]="followup" [errors]="errors"  className="border-0"
                     fieldName="length" (keyupEvent)="validateField()"/>
      <!-- [addonObject]="{after:{text:('min' | langVal)}}" -->
    </div>

  </div>

  <div class="row">
    @if(patient){
      @if(patient.is_group=='yes'){
        <div class="colstat colstat-small">
          <button (click)="openGroupAttendModal()" class="btn btn-primary">{{(hasNonAttend ? "part" : "all_arrived") | langVal}}</button>
        </div>
      }
      @else{
        <div class="colstat colstat-small">
          <app-gen-button-group [actualObj]="followup" fieldName="arrived" optionsListName="arrived_no_opts" (changeEvent)="save();"/>
        </div>
      }
    }
    @if(!(followup?.invoice_id_patient>0)){
    <div class="colstat colstat-price">
      <app-gen-button-group [actualObj]="followup" fieldName="charged" optionsListName="charged_no_opts"  [disabled]="followup?.invoice_id_patient>0" (changeEvent)="postChargedChange()"/>
    </div>
    }
    @if(patient){
<!--      @if(patient.is_group=='yes'){-->
<!--        <div class="colstat colstat-small">-->
<!--          <button (click)="openGroup()" titleLang="groups_long" class="btn btn-primary">{{"group_details" | langVal}}</button>-->
<!--        </div>-->
<!--      }-->
      @if(hasPayors  &&  !(followup?.invoice_id_patient>0)){
        <div class="colstat colstat-small">
          <app-gen-button-group [actualObj]="this" fieldName="isPayorShown" title_ph_lang="payor" optionsListName="payor_no_opts"  />
          <!-- <app-btn-ext (clickEv)="isPayorShown=!isPayorShown" text="add_payor_to_followup" [disabled]="followup?.invoice_id_patient>0" /> -->
        </div>
      }
      @if(followup?.invoice_id_patient>0){
        <div class="colstat-big btn"  style=" border-radius: 10px;    color: white;    background: var(--green);    width: fit-content; cursor: default;">
               {{"paid_by3" | langVal }}{{followup.finresource_type | langVal}} {{ followup.invoice_id_patient}}
<!--              <app-gen-input title_ph_lang="paid_by_invoice" disabled [actualObj]="followup" [errors]="errors"  className="border-0" fieldName="invoice_id_patient" (keyupEvent)="validateField()" />-->

        </div>
      }
    }
  </div>

  @if((patient && hasPayors && isPayorShown=="yes") || followup.payor_id ){
    <div class="row">
      <div class="col-12   col-sm-3 colstat">
        <div class="form-group">
          <label>{{"payor" | langVal }}</label>
          <app-gen-autocomplete title_ph_lang="payor" [actualObj]="followup" fieldName="payor_id" [disabled]="followup?.invoice_id_patient>0" [tabindex]="-1" [fuzzySearchableClasses]="['payors']"  [error]="errors.payor_id" (chosenEvent)="save()" />
        </div>
      </div>

      @if(followup.payor_id){
        <div class='colstat colstat-price price-payor-patient'>
          <label>{{"price_payor" | langVal }}</label>
          <app-gen-input [actualObj]="followup" [errors]="errors"  className="border-0" [disabled]="followup?.invoice_id_patient>0" fieldName="price_payor" (keyupEvent)="validateField()" (changeEvent)="save()" />
        </div>

        <div class='colstat colstat-price  price-payor-patient'>
          <label>{{"price_patient" | langVal }}</label>
          <div titleLang="price_patient" class="form-control border-0 disabled-input">
            {{followup.price_patient | finNumber}}
          </div>
        </div>
        <div class="colstat-small">
<!--          <button class="btn btn-primary payorprices-btn" (click)="openPayorPricesModal();" titleLang="payorprices" icon="payorprices"><span class="no-mob"> {{"tests" | langVal}}</span></button>-->
<!--          <button titleLang="payorprices" tabindex="-1" class="btn btn-primary payorprices-btn" (click)="openPayorPricesModal();" preIcon="payorprices">-->
<!--            <span> {{"payorprices_short" | langVal}}</span>-->
<!--          </button>-->
          <button titleLang="payorprices" tabindex="-1" class="btn btn-primary  payorprices-btn btns-with-icon"
                  (click)="openPayorPricesModal();" preIcon="payorprices">
            {{"payorprices_short" | langVal}}
          </button>
        </div>
      }
    </div>
  }


  @if(isManualChargeShown=='yes' && !(followup.invoice_id_patient>0)){
    <div class="row">
      <div class=" colstat colstat-big">
        <app-gen-button-group [actualObj]="followup" fieldName="charged_manualy" optionsListName="charged_manualy_no_opts" (changeEvent)="save()"/>
      </div>
      <div class="colstat colstat-small">
        @if(followup.charged_manualy=='yes'){
          <div class='input-group'>
            <app-gen-input title_ph_lang="external_invoice_id" [actualObj]="followup" [errors]="errors"  className="border-0" fieldName="invoice_id" (keyupEvent)="validateField()" />
          </div>
        }
      </div>

      @if(!isMobile  && followup.payor_id){
        <div class="colstat colstat-big">
          <app-gen-button-group [actualObj]="followup" fieldName="charged_manualy_payor" optionsListName="charged_manualy_payor_no_opts" (changeEvent)="save()"/>
        </div>
      }

    </div>
    @if(isMobile  && followup.payor_id){
      <div class="row">
        <div class="colstat colstat-small-double">
          <app-gen-button-group [actualObj]="followup" fieldName="charged_manualy_payor" optionsListName="charged_manualy_payor_no_opts" (changeEvent)="save()"/>
        </div>
      </div>
    }
  }
</div>
  @if(EnablePastFollowupsOnFollowup){
     <div class="panel-body mixed-list">
        <app-mixed-list [patient]="patient" [followup_id]="followup?.id" preventShowingInList />
      </div>
  }

  @if(permsService.perms?.clinical){
    <div class="d-flex align-items-center justify-content-between m-0">

      <div>
        <p class="followup-title">{{"medical_record_title" | langVal}}</p>
      </div>
      <div>
        @if(isSaving){
          <div [class.updated-saving]="(isMobile && ShowPersonalMemorandum=='no' && !isPMShown && !followup.remarks)" style="text-align:end;">
            <span class="strong error-soft" style="padding-inline: 20px;">{{"saving" | langVal}} </span>
          </div>
        }
      </div>
      <!-- @if(patient){ -->
        <!--      <div class="col-3 col-lg-2 ">-->
        <!--        <button  titleLang="autotexts" tabindex="-1" class="btn btn-icon" (click)="openAutoTextMenu()" icon="dots"></button>-->
        <!--      </div>-->
      <!-- } -->
      <!-- @if(patient){
        <div class="col-4 col-lg-6 justify-content-end d-flex align-items-center d-block d-xxl-none" >

          <button titleLang="autotexts" tabindex="-1" class="btn btn-primary texts-btn" (click)="openAutoTextMenu()">
            <app-icon icon="autotexts" isImg="true" />
            <span class="no-mob"> {{"autotexts" | langVal}}</span>
          </button>

          <button titleLang="tests" tabindex="-1" class="btn btn-primary texts-btn" (click)="openTests()">
            <app-icon icon="test-white" isImg="true" />
            <span class="no-mob"> {{"tests" | langVal}}</span>
          </button>
        </div>
      } -->
    </div>

    <div class="row " titleLang="medical_record">
      <div class="col-12">

        <app-wysiwyg #descriptionWysiwyg [actualObj]="followup" fieldName="description" [tabindex]="1"
                     title_ph_lang="medical_record" (changeEvent)="save()" (uploadFile)="uploadImage(null,$event)"
                     (keyupEvent)="onFocus('description');validateField()" isMedicalRecord
                     showTestsBtn (openTests)="openTests()" showAutotextsBtn (openAutotexts)="openAutoTextMenu()"
                     [allowsImageUpload]="!!patient"  [initHeight]="eventsService.isMobile ? eventsService.MobileHeight - 630 : null"  />
      </div>
    </div>

    @if(!isMobile  || (ShowPersonalMemorandum=='yes' || isPMShown || followup.remarks)){
      <div class="row m-0">
        <div class="col-12">
          <p class="followup-title">{{"personal_memorandum" | langVal}}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-12" titleLang="personal_memorandum">
          <app-wysiwyg #remarksWysiwyg [actualObj]="followup" fieldName="remarks" [tabindex]="2"
                       title_ph_lang="personal_memorandum" (changeEvent)="save()" [allowsImageUpload]="!!patient"
                       (uploadFile)="uploadImage(null,$event)"
                       (keyupEvent)="onFocus('remarks');validateField()"></app-wysiwyg>
        </div>
      </div>
    }
    @if(followup.supervisor_notes){
      <div class="row" style="border: #8fdf82;border-style: dashed;   ">
        <div class="col-12" [innerHTML]="(followup.supervisor_notes) | safeHtml"></div>
      </div>
    }



    @if(patient){
      <div  style="display: flex; justify-content: space-between; flex-wrap: wrap;">
<!--        @if(isMobile && ShowPersonalMemorandum=='no' && !isPMShown && !followup.remarks){-->

<!--        <div class="col-4 col-lg-3"  style="width: fit-content;">-->
<!--          <button titleLang="show_personal_memorandum" tabindex="-1" class="btn btn-primary"-->
<!--                  (click)="isPMShown=!isPMShown">{{"show_personal_memorandum" | langVal}}</button>-->
<!--        </div>-->
<!--         }-->
        <div  style="width: fit-content;">
          <app-gen-file-input #imageUploadInput fileType="image" [actualObj]="this" className="pointer btn-primary" fieldName="newImageObj" title_ph_lang="upload_image" [showImagePreview]="false" (changeEvent)="uploadImage($event)" [isUploading]="isUploading"/>
        </div>
        @if(isMobile && ShowPersonalMemorandum=='no' && !isPMShown && !followup.remarks){
            <div  style="width: fit-content;">
              <button titleLang="show_personal_memorandum" tabindex="-1" class="btn btn-primary"
                      (click)="isPMShown=!isPMShown">{{"show_personal_memorandum" | langVal}}</button>
            </div>
        }
          <div [class.updated-saving]="(isMobile && ShowPersonalMemorandum=='no' && !isPMShown && !followup.remarks)" style="text-align:end;color:var(--green);font-weight: 600">
            <div titleLang="updated_at">
              {{"last_update" | langVal}}: {{followup.updated_at_display}}
            </div>
          </div>

      </div>
    }
<!--class="(isMobile && ShowPersonalMemorandum=='no' && !isPMShown && !followup.remarks)?updated-saving:''"-->
  }
  <div class="images-container">
    @for(imageObj of followup.images;track imageObj){
      <div>
        <div [spinner]="imageObj.isDeleting">
          @if(!imageObj.isDeleting){
            <app-icon icon="cancel" (click)="deleteImage(imageObj)" />
          }
        </div>

        <img [src]="imageObj.url" (click)="openImage($index)"/>
      </div>
    }

  </div>
  @if(ShowVersions=='yes'){
    <div class="submit-wrap text-end">
      <button titleLang="versions" tabindex="-1" class="btn btn-primary no-mob"
              (click)="openMedilog()">{{"versions" | langVal}}</button>
    </div>
  }
  <hr>
  @if(!noActionBtns){
    <div>
      <div class="bottom-row-buttons mb-2">
        <div class="p-0 next-previous">
          <button class="btn btn-primary" (click)="save(true,true)" tabClick titleLang="next"
                  tabindex=3 postIcon="next">{{"next" | langVal}}</button>
          <button class="btn btn-primary" titleLang="previous" (click)="save(true,false)" preIcon="previous">{{"previous" | langVal}}</button>
        </div>
        <div class="action-buttons p-0">

          @if(permsService.perms?.administrative && patient &&  (patient?.is_group=="no" || ( patient?.is_group=="yes" && patient?.charge_mode=="by_group"  )) ){
<!--            <app-btn-ext text="invoice_short" (clickEv)="addInvoiceEv.emit()"   iconName="money" iconIsPre />-->
            <button class="btn btn-primary" titleLang="invoice_short" (click)="addInvoiceEv.emit()" preIcon="money-white" isImg >{{"invoice_short" | langVal}}</button>
          }

<!--          <app-btn-ext text="letter" (clickEv)="addDocumentEv.emit()"   iconName="document" iconIsPre isImg  />-->
          <button class="btn btn-primary" titleLang="letter" (click)="addDocumentEv.emit()" preIcon="document-white" isImg >{{"letter" | langVal}}</button>

<!--          <app-btn-ext text="upload_file" (clickEv)="openUploadDocumentModalEv.emit()" iconName="document" iconIsPre isImg  />-->
          <button class="btn btn-primary" titleLang="upload_file" (click)="openUploadDocumentModalEv.emit()" preIcon="document-white" isImg >{{"upload_file" | langVal}}</button>

<!--          <app-btn-ext text="see_all_patient_images"   (clickEv)="getPatientImages()" [isSpin]="isFetchingPatientImages"  iconName="image" iconIsPre />-->
          <button class="btn btn-primary" titleLang="see_all_patient_images" (click)="getPatientImages()" preIcon="image">{{"see_all_patient_images" | langVal}}</button>
        </div>

      </div>
    </div>
  }
</div>
